import Image from "next/image";

import type { NextPage } from "next";
import { useState } from "react";
import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";

import Body from "../components/Body/Body";
import Button from "../components/Button/Button";
import Caption from "../components/Caption/Caption";
import Card from "../components/Card/Card";
import Detail from "../components/Detail/Detail";
import Grid from "../components/Grid/Grid";
import HeroSection from "../components/HeroSection/HeroSection";
import HeroTitle from "../components/HeroTitle/HeroTitle";
import Page from "../components/Page/Page";
import Picture from "../components/Picture/Picture";
import Section from "../components/Section/Section";
import Title from "../components/Title/Title";
import IndexImage from "../public/images/index.jpg";

export default function Index({}: NextPage) {
	//
	const [mounted, setMounted] = useState(false);
	//
	const isSmall = useMediaQuery({ maxWidth: 599 });
	const isMedium = useMediaQuery({ minWidth: 600, maxWidth: 1079 });
	const isLarge = useMediaQuery({ minWidth: 1080 });
	//
	// useEffect only runs on the client, so now we can safely show the UI
	useEffect(() => {
		setMounted(true);
	}, []);
	if (!mounted) {
		return null;
	}
	// Testing build
	return (
		<Page
			title="An Insurance Company You Can Rely On | Global Insurance Agency"
			description="Get an insurance quote in minutes from a top-rated company. Find 24/7 support and insurance for you, your family, and your belongings."
		>
			{(isSmall || isMedium) && (
				<HeroSection theme="white" ruler={false}>
					<Grid
						smallColumnNumber={1}
						smallRowNumber={2}
						smallColumnGap="small"
						smallRowGap="small"
						smallWidth={100}
						mediumColumnNumber={1}
						mediumRowNumber={2}
						mediumColumnGap="small"
						mediumRowGap="small"
						mediumWidth={100}
						largeColumnNumber={1}
						largeRowNumber={2}
						largeColumnGap="small"
						largeRowGap="small"
						largeWidth={100}
						justify="start"
						align="center"
					>
						<HeroTitle
							first="Compare."
							second="Choose."
							third="Save."
						/>
						<Body>
							Find your perfect policy in minutes, and get better
							coverage at better prices. Compare prices from 30+
							of the best insurance carriers to find your perfect
							match and price.
						</Body>
						<Button
							internal={false}
							href="https://nwexpress.com/giausa/multi-quote/getting-started"
						>
							Get a Quote
						</Button>
					</Grid>
				</HeroSection>
			)}
			{isLarge && (
				<HeroSection theme="white" ruler={false}>
					<Grid
						smallColumnNumber={1}
						smallRowNumber={2}
						smallColumnGap="small"
						smallRowGap="small"
						smallWidth={100}
						mediumColumnNumber={1}
						mediumRowNumber={2}
						mediumColumnGap="small"
						mediumRowGap="small"
						mediumWidth={100}
						largeColumnNumber={2}
						largeRowNumber={1}
						largeColumnGap="none"
						largeRowGap="small"
						largeWidth={100}
						justify="center"
						align="center"
					>
						<Grid
							smallColumnNumber={1}
							smallRowNumber={2}
							smallColumnGap="small"
							smallRowGap="small"
							smallWidth={100}
							mediumColumnNumber={1}
							mediumRowNumber={2}
							mediumColumnGap="small"
							mediumRowGap="small"
							mediumWidth={100}
							largeColumnNumber={1}
							largeRowNumber={2}
							largeColumnGap="small"
							largeRowGap="small"
							largeWidth={75}
							justify="center"
							align="center"
						>
							<HeroTitle
								first="Compare."
								second="Choose."
								third="Save."
							/>
							<Body>
								Find your perfect policy in minutes, and get
								better coverage at better prices. Compare prices
								from 30+ of the best insurance carriers to find
								your perfect match and price.
							</Body>
						</Grid>
						<Grid
							smallColumnNumber={1}
							smallRowNumber={2}
							smallColumnGap="small"
							smallRowGap="small"
							smallWidth={100}
							mediumColumnNumber={1}
							mediumRowNumber={2}
							mediumColumnGap="small"
							mediumRowGap="small"
							mediumWidth={100}
							largeColumnNumber={2}
							largeRowNumber={6}
							largeColumnGap="none"
							largeRowGap="small"
							largeWidth={100}
							justify="start"
							align="center"
						>
							<Card text="Car Insurance" href="/products/car">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									style={{
										width: "100%",
										height: "100%",
									}}
								>
									<g clipPath="url(#clip0_1_2)">
										<path
											d="M8 10H16M7 14H8M16 14H17"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M3 18V11.41C3.00021 11.1394 3.05532 10.8717 3.162 10.623L5.481 5.213C5.63504 4.85313 5.8913 4.54637 6.21802 4.33075C6.54473 4.11513 6.92755 4.00013 7.319 4H16.681C17.0723 4.00004 17.4551 4.1149 17.7818 4.33033C18.1085 4.54576 18.3648 4.85231 18.519 5.212L20.839 10.622C20.9456 10.8714 21.0004 11.1398 21 11.411V18M3 18V20.4C3 20.5591 3.06321 20.7117 3.17574 20.8243C3.28826 20.9368 3.44087 21 3.6 21H6.4C6.55913 21 6.71174 20.9368 6.82426 20.8243C6.93679 20.7117 7 20.5591 7 20.4V18M3 18H7M21 18V20.4C21 20.5591 20.9368 20.7117 20.8243 20.8243C20.7117 20.9368 20.5591 21 20.4 21H17.6C17.5212 21 17.4432 20.9845 17.3704 20.9543C17.2976 20.9242 17.2315 20.88 17.1757 20.8243C17.12 20.7685 17.0758 20.7024 17.0457 20.6296C17.0155 20.5568 17 20.4788 17 20.4V18M21 18H17M7 18H17"
											stroke="currentColor"
											strokeWidth="1.5"
										/>
									</g>
								</svg>
							</Card>
							<Card text="Home Insurance" href="/products/home">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									style={{
										width: "100%",
										height: "100%",
									}}
								>
									<g clipPath="url(#clip0_1_10)">
										<path
											d="M3 9.5L12 4L21 9.5M19 13V19.4C19 19.5591 18.9368 19.7117 18.8243 19.8243C18.7117 19.9368 18.5591 20 18.4 20H5.6C5.44087 20 5.28826 19.9368 5.17574 19.8243C5.06321 19.7117 5 19.5591 5 19.4V13"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M12.502 9.13L14.551 9.661C14.815 9.73 15.001 9.97 14.992 10.243C14.826 15.232 12 16 12 16C12 16 9.174 15.232 9.008 10.243C9.00443 10.1103 9.04616 9.98037 9.12632 9.87458C9.20649 9.76878 9.3203 9.69345 9.449 9.661L11.499 9.131C11.8279 9.0458 12.1731 9.0458 12.502 9.131V9.13Z"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</g>
								</svg>
							</Card>
							<Card text="Car and Home" href="/products/carhome">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									style={{
										width: "100%",
										height: "100%",
									}}
								>
									<g clipPath="url(#clip0_1_17)">
										<path
											d="M6 20H3V6L12 4L21 6V20H18M6 20H18M6 20V16M18 20V16M6 12V8H18V12M6 12H18M6 12V16M18 12V16M6 16H18"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</g>
								</svg>
							</Card>
							<Card text="Condo Insurance" href="/products/condo">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									style={{
										width: "100%",
										height: "100%",
									}}
								>
									<g clipPath="url(#clip0_1_116)">
										<path
											d="M10 9.01L10.01 8.999M14 9.01L14.01 8.999M10 13.01L10.01 12.999M14 13.01L14.01 12.999M10 17.01L10.01 16.999M14 17.01L14.01 16.999M6 20.4V5.6C6 5.44087 6.06321 5.28826 6.17574 5.17574C6.28826 5.06321 6.44087 5 6.6 5H12V3.6C12 3.44087 12.0632 3.28826 12.1757 3.17574C12.2883 3.06321 12.4409 3 12.6 3H17.4C17.5591 3 17.7117 3.06321 17.8243 3.17574C17.9368 3.28826 18 3.44087 18 3.6V20.4C18 20.5591 17.9368 20.7117 17.8243 20.8243C17.7117 20.9368 17.5591 21 17.4 21H6.6C6.44087 21 6.28826 20.9368 6.17574 20.8243C6.06321 20.7117 6 20.5591 6 20.4V20.4Z"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</g>
								</svg>
							</Card>
							<Card
								text="Renters Insurance"
								href="/products/renters"
							>
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									style={{
										width: "100%",
										height: "100%",
									}}
								>
									<g clipPath="url(#clip0_1_112)">
										<path
											d="M7 9.01L7.01 8.999M11 9.01L11.01 8.999M7 13.01L7.01 12.999M11 13.01L11.01 12.999M7 17.01L7.01 16.999M11 17.01L11.01 16.999M15 21H3.6C3.44087 21 3.28826 20.9368 3.17574 20.8243C3.06321 20.7117 3 20.5591 3 20.4V5.6C3 5.44087 3.06321 5.28826 3.17574 5.17574C3.28826 5.06321 3.44087 5 3.6 5H9V3.6C9 3.44087 9.06321 3.28826 9.17574 3.17574C9.28826 3.06321 9.44087 3 9.6 3H14.4C14.5591 3 14.7117 3.06321 14.8243 3.17574C14.9368 3.28826 15 3.44087 15 3.6V9M15 21H20.4C20.5591 21 20.7117 20.9368 20.8243 20.8243C20.9368 20.7117 21 20.5591 21 20.4V9.6C21 9.44087 20.9368 9.28826 20.8243 9.17574C20.7117 9.06321 20.5591 9 20.4 9H15M15 21V17M15 9V13M15 13H17M15 13V17M15 17H17"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</g>
								</svg>
							</Card>
							<Card text="Aircraft" href="/products/aircraft">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									style={{
										width: "100%",
										height: "100%",
									}}
								>
									<g clipPath="url(#clip0_1_44)">
										<path
											d="M10.5 4.5V9.167C10.5002 9.26897 10.4744 9.3693 10.4251 9.45854C10.3757 9.54777 10.3045 9.62296 10.218 9.677L2.782 14.324C2.69582 14.3779 2.62473 14.4527 2.57541 14.5416C2.52608 14.6304 2.50014 14.7304 2.5 14.832V15.732C2.49999 15.8232 2.52079 15.9132 2.56081 15.9952C2.60082 16.0772 2.65901 16.149 2.73093 16.2051C2.80286 16.2612 2.88663 16.3002 2.97589 16.3191C3.06514 16.3379 3.15752 16.3362 3.246 16.314L9.754 14.686C9.84248 14.6638 9.93486 14.6621 10.0241 14.6809C10.1134 14.6998 10.1971 14.7388 10.2691 14.7949C10.341 14.851 10.3992 14.9228 10.4392 15.0048C10.4792 15.0868 10.5 15.1768 10.5 15.268V18.228C10.4999 18.3134 10.4816 18.3979 10.4462 18.4756C10.4109 18.5534 10.3593 18.6228 10.295 18.679L8.135 20.569C7.677 20.971 8.038 21.72 8.637 21.611L11.893 21.02C11.9638 21.0072 12.0362 21.0072 12.107 21.02L15.363 21.611C15.962 21.721 16.323 20.971 15.865 20.57L13.705 18.68C13.6406 18.6236 13.5889 18.5542 13.5536 18.4762C13.5182 18.3982 13.4999 18.3136 13.5 18.228V15.268C13.5 15.1769 13.5208 15.087 13.5608 15.0051C13.6007 14.9232 13.6588 14.8515 13.7306 14.7954C13.8024 14.7393 13.8861 14.7003 13.9752 14.6813C14.0643 14.6624 14.1566 14.664 14.245 14.686L20.755 16.314C20.8434 16.336 20.9357 16.3376 21.0248 16.3187C21.1139 16.2997 21.1976 16.2607 21.2694 16.2046C21.3412 16.1485 21.3993 16.0768 21.4392 15.9949C21.4792 15.913 21.5 15.8231 21.5 15.732V14.832C21.4999 14.7304 21.4739 14.6304 21.4246 14.5416C21.3753 14.4527 21.3042 14.3779 21.218 14.324L13.782 9.676C13.6957 9.62205 13.6245 9.54702 13.5752 9.45797C13.5258 9.36893 13.5 9.2688 13.5 9.167V4.5C13.5 4.10218 13.342 3.72064 13.0607 3.43934C12.7794 3.15804 12.3978 3 12 3C11.6022 3 11.2206 3.15804 10.9393 3.43934C10.658 3.72064 10.5 4.10218 10.5 4.5V4.5Z"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</g>
								</svg>
							</Card>
							<Card text="Watercraft" href="/products/watercraft">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									style={{
										width: "100%",
										height: "100%",
									}}
								>
									<g clipPath="url(#clip0_2_133)">
										<path
											d="M3 20C3 20 6 22.5 12 20C18 17.5 21 20 21 20"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M5 17L3 12.1948C3 12.1431 3.08429 12.0936 3.23431 12.0571C3.38434 12.0205 3.58783 12 3.8 12H11"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M19 17L21 12.1948C21 12.1431 20.8946 12.0936 20.7071 12.0571C20.5196 12.0205 20.2652 12 20 12H11"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M14 12L14 3L5 12"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</g>
								</svg>
							</Card>
							<Card
								text="General Liability"
								href="/products/generalliability"
							>
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									style={{
										width: "100%",
										height: "100%",
									}}
								>
									<g clipPath="url(#clip0_1_55)">
										<path
											d="M3 10V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H19C19.5304 21 20.0391 20.7893 20.4142 20.4142C20.7893 20.0391 21 19.5304 21 19V10"
											stroke="currentColor"
											strokeWidth="1.5"
										/>
										<path
											d="M14.833 21V15C14.833 14.4696 14.6223 13.9609 14.2472 13.5858C13.8721 13.2107 13.3634 13 12.833 13H10.833C10.3026 13 9.79387 13.2107 9.41879 13.5858C9.04372 13.9609 8.83301 14.4696 8.83301 15V21"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeMiterlimit="16"
										/>
										<path
											d="M21.818 9.364L20.124 3.435C20.0881 3.30965 20.0124 3.19939 19.9083 3.1209C19.8042 3.04241 19.6774 2.99997 19.547 3H15.5L15.975 8.704C15.9822 8.79572 16.0112 8.8844 16.0595 8.96267C16.1079 9.04093 16.1742 9.10653 16.253 9.154C16.643 9.387 17.405 9.817 18 10C19.016 10.313 20.5 10.2 21.346 10.096C21.4282 10.0854 21.5072 10.0569 21.5773 10.0126C21.6474 9.96835 21.707 9.90929 21.752 9.8396C21.7969 9.7699 21.8261 9.69123 21.8375 9.60909C21.8489 9.52695 21.8423 9.44331 21.818 9.364Z"
											stroke="currentColor"
											strokeWidth="1.5"
										/>
										<path
											d="M14 10C14.568 9.825 15.288 9.426 15.69 9.188C15.7837 9.13222 15.8597 9.05108 15.9093 8.95393C15.9588 8.85678 15.9799 8.7476 15.97 8.639L15.5 3H8.5L8.03 8.639C8.01994 8.74777 8.04089 8.85716 8.09045 8.9545C8.14001 9.05184 8.21613 9.13315 8.31 9.189C8.712 9.426 9.432 9.825 10 10C11.493 10.46 12.507 10.46 14 10V10Z"
											stroke="currentColor"
											strokeWidth="1.5"
										/>
										<path
											d="M3.87601 3.435L2.18201 9.365C2.1581 9.44418 2.1517 9.52762 2.16327 9.60951C2.17484 9.69141 2.2041 9.76981 2.24901 9.83927C2.29392 9.90873 2.35341 9.96758 2.42334 10.0117C2.49328 10.0559 2.57199 10.0843 2.65401 10.095C3.49901 10.2 4.98401 10.312 6.00001 10C6.59501 9.817 7.35801 9.387 7.74701 9.155C7.82594 9.10743 7.89236 9.04168 7.94072 8.96323C7.98908 8.88477 8.01798 8.79589 8.02501 8.704L8.50001 3H4.45301C4.32263 2.99997 4.19579 3.04241 4.09168 3.1209C3.98757 3.19939 3.91186 3.30965 3.87601 3.435V3.435Z"
											stroke="currentColor"
											strokeWidth="1.5"
										/>
									</g>
								</svg>
							</Card>
							<Card
								text="Workers Comp"
								href="/products/workerscomp"
							>
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									style={{
										width: "100%",
										height: "100%",
									}}
								>
									<g clipPath="url(#clip0_1_87)">
										<path
											d="M18 10C18 9 17 8 15 8H14C13.2044 8 12.4413 7.68393 11.8787 7.12132C11.3161 6.55871 11 5.79565 11 5V2M18 21H21V12H18V16.5M18 21V16.5M18 21H3V17L6.5 14L10.5 16.5L14.5 14L18 16.5M21 10C21 4 17 4 17 4C17 4 21 4.5 21 2"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</g>
								</svg>
							</Card>
							<Card
								text="Commercial Auto"
								href="/products/commercialauto"
							>
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									style={{
										width: "100%",
										height: "100%",
									}}
								>
									<g clipPath="url(#clip0_1_49)">
										<path
											d="M7 19C7.53043 19 8.03914 18.7893 8.41421 18.4142C8.78929 18.0391 9 17.5304 9 17C9 16.4696 8.78929 15.9609 8.41421 15.5858C8.03914 15.2107 7.53043 15 7 15C6.46957 15 5.96086 15.2107 5.58579 15.5858C5.21071 15.9609 5 16.4696 5 17C5 17.5304 5.21071 18.0391 5.58579 18.4142C5.96086 18.7893 6.46957 19 7 19V19ZM17 19C17.5304 19 18.0391 18.7893 18.4142 18.4142C18.7893 18.0391 19 17.5304 19 17C19 16.4696 18.7893 15.9609 18.4142 15.5858C18.0391 15.2107 17.5304 15 17 15C16.4696 15 15.9609 15.2107 15.5858 15.5858C15.2107 15.9609 15 16.4696 15 17C15 17.5304 15.2107 18.0391 15.5858 18.4142C15.9609 18.7893 16.4696 19 17 19V19Z"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeMiterlimit="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M14 17V6.6C14 6.44087 13.9368 6.28826 13.8243 6.17574C13.7117 6.06321 13.5591 6 13.4 6H2.6C2.44087 6 2.28826 6.06321 2.17574 6.17574C2.06321 6.28826 2 6.44087 2 6.6V16.4C2 16.4788 2.01552 16.5568 2.04567 16.6296C2.07583 16.7024 2.12002 16.7685 2.17574 16.8243C2.23145 16.88 2.29759 16.9242 2.37039 16.9543C2.44319 16.9845 2.52121 17 2.6 17H4.65M14 17H9.05M14 9H19.61C19.726 9.00003 19.8395 9.03367 19.9367 9.09685C20.034 9.16003 20.1108 9.25005 20.158 9.356L21.948 13.384C21.9821 13.4605 21.9998 13.5433 22 13.627V16.4C22 16.4788 21.9845 16.5568 21.9543 16.6296C21.9242 16.7024 21.88 16.7685 21.8243 16.8243C21.7685 16.88 21.7024 16.9242 21.6296 16.9543C21.5568 16.9845 21.4788 17 21.4 17H19.5M14 17H15"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
										/>
									</g>
								</svg>
							</Card>
							<Card
								text="More Choices"
								href="https://rater.gia-usa.com/contact"
							>
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									style={{
										width: "100%",
										height: "100%",
									}}
								>
									<path
										d="M7 12.5C7.13261 12.5 7.25979 12.4473 7.35355 12.3536C7.44732 12.2598 7.5 12.1326 7.5 12C7.5 11.8674 7.44732 11.7402 7.35355 11.6464C7.25979 11.5527 7.13261 11.5 7 11.5C6.86739 11.5 6.74021 11.5527 6.64645 11.6464C6.55268 11.7402 6.5 11.8674 6.5 12C6.5 12.1326 6.55268 12.2598 6.64645 12.3536C6.74021 12.4473 6.86739 12.5 7 12.5V12.5ZM12 12.5C12.1326 12.5 12.2598 12.4473 12.3536 12.3536C12.4473 12.2598 12.5 12.1326 12.5 12C12.5 11.8674 12.4473 11.7402 12.3536 11.6464C12.2598 11.5527 12.1326 11.5 12 11.5C11.8674 11.5 11.7402 11.5527 11.6464 11.6464C11.5527 11.7402 11.5 11.8674 11.5 12C11.5 12.1326 11.5527 12.2598 11.6464 12.3536C11.7402 12.4473 11.8674 12.5 12 12.5ZM17 12.5C17.1326 12.5 17.2598 12.4473 17.3536 12.3536C17.4473 12.2598 17.5 12.1326 17.5 12C17.5 11.8674 17.4473 11.7402 17.3536 11.6464C17.2598 11.5527 17.1326 11.5 17 11.5C16.8674 11.5 16.7402 11.5527 16.6464 11.6464C16.5527 11.7402 16.5 11.8674 16.5 12C16.5 12.1326 16.5527 12.2598 16.6464 12.3536C16.7402 12.4473 16.8674 12.5 17 12.5Z"
										fill="currentColor"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22Z"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</Card>
						</Grid>
					</Grid>
				</HeroSection>
			)}
			{(isSmall || isMedium) && (
				<Section theme="light" ruler={false} padding="small">
					<Grid
						smallColumnNumber={1}
						smallRowNumber={2}
						smallColumnGap="none"
						smallRowGap="medium"
						smallWidth={100}
						mediumColumnNumber={1}
						mediumRowNumber={2}
						mediumColumnGap="none"
						mediumRowGap="medium"
						mediumWidth={100}
						largeColumnNumber={1}
						largeRowNumber={2}
						largeColumnGap="none"
						largeRowGap="medium"
						largeWidth={100}
						justify="start"
						align="center"
					>
						<Grid
							smallColumnNumber={1}
							smallRowNumber={2}
							smallColumnGap="none"
							smallRowGap="small"
							smallWidth={100}
							mediumColumnNumber={1}
							mediumRowNumber={2}
							mediumColumnGap="none"
							mediumRowGap="small"
							mediumWidth={100}
							largeColumnNumber={1}
							largeRowNumber={2}
							largeColumnGap="none"
							largeRowGap="small"
							largeWidth={100}
							justify="start"
							align="center"
						>
							<Caption>Get a Quote in 5 Minutes</Caption>
							<Title>
								We can help you get the lowest possible rates
							</Title>
						</Grid>
						<Grid
							smallColumnNumber={1}
							smallRowNumber={11}
							smallColumnGap="none"
							smallRowGap="small"
							smallWidth={100}
							mediumColumnNumber={1}
							mediumRowNumber={11}
							mediumColumnGap="none"
							mediumRowGap="small"
							mediumWidth={100}
							largeColumnNumber={1}
							largeRowNumber={11}
							largeColumnGap="none"
							largeRowGap="small"
							largeWidth={100}
							justify="start"
							align="center"
						>
							<Card text="Car Insurance" href="/products/car">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									style={{ width: "100%", height: "100%" }}
								>
									<g clipPath="url(#clip0_1_2)">
										<path
											d="M8 10H16M7 14H8M16 14H17"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M3 18V11.41C3.00021 11.1394 3.05532 10.8717 3.162 10.623L5.481 5.213C5.63504 4.85313 5.8913 4.54637 6.21802 4.33075C6.54473 4.11513 6.92755 4.00013 7.319 4H16.681C17.0723 4.00004 17.4551 4.1149 17.7818 4.33033C18.1085 4.54576 18.3648 4.85231 18.519 5.212L20.839 10.622C20.9456 10.8714 21.0004 11.1398 21 11.411V18M3 18V20.4C3 20.5591 3.06321 20.7117 3.17574 20.8243C3.28826 20.9368 3.44087 21 3.6 21H6.4C6.55913 21 6.71174 20.9368 6.82426 20.8243C6.93679 20.7117 7 20.5591 7 20.4V18M3 18H7M21 18V20.4C21 20.5591 20.9368 20.7117 20.8243 20.8243C20.7117 20.9368 20.5591 21 20.4 21H17.6C17.5212 21 17.4432 20.9845 17.3704 20.9543C17.2976 20.9242 17.2315 20.88 17.1757 20.8243C17.12 20.7685 17.0758 20.7024 17.0457 20.6296C17.0155 20.5568 17 20.4788 17 20.4V18M21 18H17M7 18H17"
											stroke="currentColor"
											strokeWidth="1.5"
										/>
									</g>
								</svg>
							</Card>
							<Card text="Home Insurance" href="/products/home">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									style={{ width: "100%", height: "100%" }}
								>
									<g clipPath="url(#clip0_1_10)">
										<path
											d="M3 9.5L12 4L21 9.5M19 13V19.4C19 19.5591 18.9368 19.7117 18.8243 19.8243C18.7117 19.9368 18.5591 20 18.4 20H5.6C5.44087 20 5.28826 19.9368 5.17574 19.8243C5.06321 19.7117 5 19.5591 5 19.4V13"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M12.502 9.13L14.551 9.661C14.815 9.73 15.001 9.97 14.992 10.243C14.826 15.232 12 16 12 16C12 16 9.174 15.232 9.008 10.243C9.00443 10.1103 9.04616 9.98037 9.12632 9.87458C9.20649 9.76878 9.3203 9.69345 9.449 9.661L11.499 9.131C11.8279 9.0458 12.1731 9.0458 12.502 9.131V9.13Z"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</g>
								</svg>
							</Card>
							<Card text="Car and Home" href="/products/carhome">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									style={{ width: "100%", height: "100%" }}
								>
									<g clipPath="url(#clip0_1_17)">
										<path
											d="M6 20H3V6L12 4L21 6V20H18M6 20H18M6 20V16M18 20V16M6 12V8H18V12M6 12H18M6 12V16M18 12V16M6 16H18"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</g>
								</svg>
							</Card>
							<Card text="Condo Insurance" href="/products/condo">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									style={{ width: "100%", height: "100%" }}
								>
									<g clipPath="url(#clip0_1_116)">
										<path
											d="M10 9.01L10.01 8.999M14 9.01L14.01 8.999M10 13.01L10.01 12.999M14 13.01L14.01 12.999M10 17.01L10.01 16.999M14 17.01L14.01 16.999M6 20.4V5.6C6 5.44087 6.06321 5.28826 6.17574 5.17574C6.28826 5.06321 6.44087 5 6.6 5H12V3.6C12 3.44087 12.0632 3.28826 12.1757 3.17574C12.2883 3.06321 12.4409 3 12.6 3H17.4C17.5591 3 17.7117 3.06321 17.8243 3.17574C17.9368 3.28826 18 3.44087 18 3.6V20.4C18 20.5591 17.9368 20.7117 17.8243 20.8243C17.7117 20.9368 17.5591 21 17.4 21H6.6C6.44087 21 6.28826 20.9368 6.17574 20.8243C6.06321 20.7117 6 20.5591 6 20.4V20.4Z"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</g>
								</svg>
							</Card>
							<Card
								text="Renters Insurance"
								href="/products/renters"
							>
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									style={{ width: "100%", height: "100%" }}
								>
									<g clipPath="url(#clip0_1_112)">
										<path
											d="M7 9.01L7.01 8.999M11 9.01L11.01 8.999M7 13.01L7.01 12.999M11 13.01L11.01 12.999M7 17.01L7.01 16.999M11 17.01L11.01 16.999M15 21H3.6C3.44087 21 3.28826 20.9368 3.17574 20.8243C3.06321 20.7117 3 20.5591 3 20.4V5.6C3 5.44087 3.06321 5.28826 3.17574 5.17574C3.28826 5.06321 3.44087 5 3.6 5H9V3.6C9 3.44087 9.06321 3.28826 9.17574 3.17574C9.28826 3.06321 9.44087 3 9.6 3H14.4C14.5591 3 14.7117 3.06321 14.8243 3.17574C14.9368 3.28826 15 3.44087 15 3.6V9M15 21H20.4C20.5591 21 20.7117 20.9368 20.8243 20.8243C20.9368 20.7117 21 20.5591 21 20.4V9.6C21 9.44087 20.9368 9.28826 20.8243 9.17574C20.7117 9.06321 20.5591 9 20.4 9H15M15 21V17M15 9V13M15 13H17M15 13V17M15 17H17"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</g>
								</svg>
							</Card>
							<Card text="Aircraft" href="/products/aircraft">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									style={{ width: "100%", height: "100%" }}
								>
									<g clipPath="url(#clip0_1_44)">
										<path
											d="M10.5 4.5V9.167C10.5002 9.26897 10.4744 9.3693 10.4251 9.45854C10.3757 9.54777 10.3045 9.62296 10.218 9.677L2.782 14.324C2.69582 14.3779 2.62473 14.4527 2.57541 14.5416C2.52608 14.6304 2.50014 14.7304 2.5 14.832V15.732C2.49999 15.8232 2.52079 15.9132 2.56081 15.9952C2.60082 16.0772 2.65901 16.149 2.73093 16.2051C2.80286 16.2612 2.88663 16.3002 2.97589 16.3191C3.06514 16.3379 3.15752 16.3362 3.246 16.314L9.754 14.686C9.84248 14.6638 9.93486 14.6621 10.0241 14.6809C10.1134 14.6998 10.1971 14.7388 10.2691 14.7949C10.341 14.851 10.3992 14.9228 10.4392 15.0048C10.4792 15.0868 10.5 15.1768 10.5 15.268V18.228C10.4999 18.3134 10.4816 18.3979 10.4462 18.4756C10.4109 18.5534 10.3593 18.6228 10.295 18.679L8.135 20.569C7.677 20.971 8.038 21.72 8.637 21.611L11.893 21.02C11.9638 21.0072 12.0362 21.0072 12.107 21.02L15.363 21.611C15.962 21.721 16.323 20.971 15.865 20.57L13.705 18.68C13.6406 18.6236 13.5889 18.5542 13.5536 18.4762C13.5182 18.3982 13.4999 18.3136 13.5 18.228V15.268C13.5 15.1769 13.5208 15.087 13.5608 15.0051C13.6007 14.9232 13.6588 14.8515 13.7306 14.7954C13.8024 14.7393 13.8861 14.7003 13.9752 14.6813C14.0643 14.6624 14.1566 14.664 14.245 14.686L20.755 16.314C20.8434 16.336 20.9357 16.3376 21.0248 16.3187C21.1139 16.2997 21.1976 16.2607 21.2694 16.2046C21.3412 16.1485 21.3993 16.0768 21.4392 15.9949C21.4792 15.913 21.5 15.8231 21.5 15.732V14.832C21.4999 14.7304 21.4739 14.6304 21.4246 14.5416C21.3753 14.4527 21.3042 14.3779 21.218 14.324L13.782 9.676C13.6957 9.62205 13.6245 9.54702 13.5752 9.45797C13.5258 9.36893 13.5 9.2688 13.5 9.167V4.5C13.5 4.10218 13.342 3.72064 13.0607 3.43934C12.7794 3.15804 12.3978 3 12 3C11.6022 3 11.2206 3.15804 10.9393 3.43934C10.658 3.72064 10.5 4.10218 10.5 4.5V4.5Z"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</g>
								</svg>
							</Card>
							<Card text="Watercraft" href="/products/watercraft">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									style={{ width: "100%", height: "100%" }}
								>
									<g clipPath="url(#clip0_2_133)">
										<path
											d="M3 20C3 20 6 22.5 12 20C18 17.5 21 20 21 20"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M5 17L3 12.1948C3 12.1431 3.08429 12.0936 3.23431 12.0571C3.38434 12.0205 3.58783 12 3.8 12H11"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M19 17L21 12.1948C21 12.1431 20.8946 12.0936 20.7071 12.0571C20.5196 12.0205 20.2652 12 20 12H11"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M14 12L14 3L5 12"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</g>
								</svg>
							</Card>
							<Card
								text="General Liability"
								href="/products/generalliability"
							>
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									style={{ width: "100%", height: "100%" }}
								>
									<g clipPath="url(#clip0_1_55)">
										<path
											d="M3 10V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H19C19.5304 21 20.0391 20.7893 20.4142 20.4142C20.7893 20.0391 21 19.5304 21 19V10"
											stroke="currentColor"
											strokeWidth="1.5"
										/>
										<path
											d="M14.833 21V15C14.833 14.4696 14.6223 13.9609 14.2472 13.5858C13.8721 13.2107 13.3634 13 12.833 13H10.833C10.3026 13 9.79387 13.2107 9.41879 13.5858C9.04372 13.9609 8.83301 14.4696 8.83301 15V21"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeMiterlimit="16"
										/>
										<path
											d="M21.818 9.364L20.124 3.435C20.0881 3.30965 20.0124 3.19939 19.9083 3.1209C19.8042 3.04241 19.6774 2.99997 19.547 3H15.5L15.975 8.704C15.9822 8.79572 16.0112 8.8844 16.0595 8.96267C16.1079 9.04093 16.1742 9.10653 16.253 9.154C16.643 9.387 17.405 9.817 18 10C19.016 10.313 20.5 10.2 21.346 10.096C21.4282 10.0854 21.5072 10.0569 21.5773 10.0126C21.6474 9.96835 21.707 9.90929 21.752 9.8396C21.7969 9.7699 21.8261 9.69123 21.8375 9.60909C21.8489 9.52695 21.8423 9.44331 21.818 9.364Z"
											stroke="currentColor"
											strokeWidth="1.5"
										/>
										<path
											d="M14 10C14.568 9.825 15.288 9.426 15.69 9.188C15.7837 9.13222 15.8597 9.05108 15.9093 8.95393C15.9588 8.85678 15.9799 8.7476 15.97 8.639L15.5 3H8.5L8.03 8.639C8.01994 8.74777 8.04089 8.85716 8.09045 8.9545C8.14001 9.05184 8.21613 9.13315 8.31 9.189C8.712 9.426 9.432 9.825 10 10C11.493 10.46 12.507 10.46 14 10V10Z"
											stroke="currentColor"
											strokeWidth="1.5"
										/>
										<path
											d="M3.87601 3.435L2.18201 9.365C2.1581 9.44418 2.1517 9.52762 2.16327 9.60951C2.17484 9.69141 2.2041 9.76981 2.24901 9.83927C2.29392 9.90873 2.35341 9.96758 2.42334 10.0117C2.49328 10.0559 2.57199 10.0843 2.65401 10.095C3.49901 10.2 4.98401 10.312 6.00001 10C6.59501 9.817 7.35801 9.387 7.74701 9.155C7.82594 9.10743 7.89236 9.04168 7.94072 8.96323C7.98908 8.88477 8.01798 8.79589 8.02501 8.704L8.50001 3H4.45301C4.32263 2.99997 4.19579 3.04241 4.09168 3.1209C3.98757 3.19939 3.91186 3.30965 3.87601 3.435V3.435Z"
											stroke="currentColor"
											strokeWidth="1.5"
										/>
									</g>
								</svg>
							</Card>
							<Card
								text="Workers Comp"
								href="/products/workerscomp"
							>
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									style={{ width: "100%", height: "100%" }}
								>
									<g clipPath="url(#clip0_1_87)">
										<path
											d="M18 10C18 9 17 8 15 8H14C13.2044 8 12.4413 7.68393 11.8787 7.12132C11.3161 6.55871 11 5.79565 11 5V2M18 21H21V12H18V16.5M18 21V16.5M18 21H3V17L6.5 14L10.5 16.5L14.5 14L18 16.5M21 10C21 4 17 4 17 4C17 4 21 4.5 21 2"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</g>
								</svg>
							</Card>
							<Card
								text="Commercial Auto"
								href="/products/commercialauto"
							>
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									style={{ width: "100%", height: "100%" }}
								>
									<g clipPath="url(#clip0_1_49)">
										<path
											d="M7 19C7.53043 19 8.03914 18.7893 8.41421 18.4142C8.78929 18.0391 9 17.5304 9 17C9 16.4696 8.78929 15.9609 8.41421 15.5858C8.03914 15.2107 7.53043 15 7 15C6.46957 15 5.96086 15.2107 5.58579 15.5858C5.21071 15.9609 5 16.4696 5 17C5 17.5304 5.21071 18.0391 5.58579 18.4142C5.96086 18.7893 6.46957 19 7 19V19ZM17 19C17.5304 19 18.0391 18.7893 18.4142 18.4142C18.7893 18.0391 19 17.5304 19 17C19 16.4696 18.7893 15.9609 18.4142 15.5858C18.0391 15.2107 17.5304 15 17 15C16.4696 15 15.9609 15.2107 15.5858 15.5858C15.2107 15.9609 15 16.4696 15 17C15 17.5304 15.2107 18.0391 15.5858 18.4142C15.9609 18.7893 16.4696 19 17 19V19Z"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeMiterlimit="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M14 17V6.6C14 6.44087 13.9368 6.28826 13.8243 6.17574C13.7117 6.06321 13.5591 6 13.4 6H2.6C2.44087 6 2.28826 6.06321 2.17574 6.17574C2.06321 6.28826 2 6.44087 2 6.6V16.4C2 16.4788 2.01552 16.5568 2.04567 16.6296C2.07583 16.7024 2.12002 16.7685 2.17574 16.8243C2.23145 16.88 2.29759 16.9242 2.37039 16.9543C2.44319 16.9845 2.52121 17 2.6 17H4.65M14 17H9.05M14 9H19.61C19.726 9.00003 19.8395 9.03367 19.9367 9.09685C20.034 9.16003 20.1108 9.25005 20.158 9.356L21.948 13.384C21.9821 13.4605 21.9998 13.5433 22 13.627V16.4C22 16.4788 21.9845 16.5568 21.9543 16.6296C21.9242 16.7024 21.88 16.7685 21.8243 16.8243C21.7685 16.88 21.7024 16.9242 21.6296 16.9543C21.5568 16.9845 21.4788 17 21.4 17H19.5M14 17H15"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
										/>
									</g>
								</svg>
							</Card>
							<Card
								text="More Choices"
								href="https://rater.gia-usa.com/contact"
							>
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									style={{ width: "100%", height: "100%" }}
								>
									<path
										d="M7 12.5C7.13261 12.5 7.25979 12.4473 7.35355 12.3536C7.44732 12.2598 7.5 12.1326 7.5 12C7.5 11.8674 7.44732 11.7402 7.35355 11.6464C7.25979 11.5527 7.13261 11.5 7 11.5C6.86739 11.5 6.74021 11.5527 6.64645 11.6464C6.55268 11.7402 6.5 11.8674 6.5 12C6.5 12.1326 6.55268 12.2598 6.64645 12.3536C6.74021 12.4473 6.86739 12.5 7 12.5V12.5ZM12 12.5C12.1326 12.5 12.2598 12.4473 12.3536 12.3536C12.4473 12.2598 12.5 12.1326 12.5 12C12.5 11.8674 12.4473 11.7402 12.3536 11.6464C12.2598 11.5527 12.1326 11.5 12 11.5C11.8674 11.5 11.7402 11.5527 11.6464 11.6464C11.5527 11.7402 11.5 11.8674 11.5 12C11.5 12.1326 11.5527 12.2598 11.6464 12.3536C11.7402 12.4473 11.8674 12.5 12 12.5ZM17 12.5C17.1326 12.5 17.2598 12.4473 17.3536 12.3536C17.4473 12.2598 17.5 12.1326 17.5 12C17.5 11.8674 17.4473 11.7402 17.3536 11.6464C17.2598 11.5527 17.1326 11.5 17 11.5C16.8674 11.5 16.7402 11.5527 16.6464 11.6464C16.5527 11.7402 16.5 11.8674 16.5 12C16.5 12.1326 16.5527 12.2598 16.6464 12.3536C16.7402 12.4473 16.8674 12.5 17 12.5Z"
										fill="currentColor"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22Z"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</Card>
						</Grid>
					</Grid>
				</Section>
			)}
			{(isSmall || isMedium) && (
				<Section theme="white" ruler={false} padding="small">
					<Grid
						smallColumnNumber={2}
						smallRowNumber={4}
						smallColumnGap="none"
						smallRowGap="medium"
						smallWidth={100}
						mediumColumnNumber={2}
						mediumRowNumber={4}
						mediumColumnGap="none"
						mediumRowGap="medium"
						mediumWidth={100}
						largeColumnNumber={4}
						largeRowNumber={2}
						largeColumnGap="none"
						largeRowGap="medium"
						largeWidth={100}
						justify="center"
						align="center"
					>
						<Image
							src="/nationwide-gia2.svg"
							alt="Nationwide"
							width={100}
							height={40}
						/>
						<Image
							src="/progressive-gia2.svg"
							alt="Progressive"
							width={100}
							height={12}
						/>

						<Image
							src="/mercury-gia2.svg"
							alt="Mercury Insurance"
							width={93}
							height={19}
						/>

						<Image
							src="/lemonade-gia2.svg"
							alt="Lemonade"
							width={100}
							height={23}
						/>

						<Image
							src="/hagerty-gia2.svg"
							alt="Hagerty"
							width={100}
							height={20}
						/>

						<Image
							src="/chubb-gia2.svg"
							alt="Chubb"
							width={100}
							height={12}
						/>

						<Image
							src="/travelers-gia.svg"
							alt="Travelers"
							width={100}
							height={50}
						/>

						<Image
							src="/hartford-gia2.svg"
							alt="The Hartford"
							width={100}
							height={60}
						/>
					</Grid>
				</Section>
			)}
			{isLarge && (
				<Section theme="light" ruler={false} padding="medium">
					<Grid
						smallColumnNumber={2}
						smallRowNumber={4}
						smallColumnGap="small"
						smallRowGap="small"
						smallWidth={100}
						mediumColumnNumber={2}
						mediumRowNumber={4}
						mediumColumnGap="small"
						mediumRowGap="small"
						mediumWidth={100}
						largeColumnNumber={4}
						largeRowNumber={2}
						largeColumnGap="none"
						largeRowGap="medium"
						largeWidth={100}
						justify="center"
						align="center"
					>
						<Image
							src="/nationwide-gia2.svg"
							alt="Nationwide"
							width={100}
							height={40}
						/>
						<Image
							src="/progressive-gia2.svg"
							alt="Progressive"
							width={100}
							height={12}
						/>

						<Image
							src="/mercury-gia2.svg"
							alt="Mercury Insurance"
							width={93}
							height={19}
						/>

						<Image
							src="/lemonade-gia2.svg"
							alt="Lemonade"
							width={100}
							height={23}
						/>

						<Image
							src="/hagerty-gia2.svg"
							alt="Hagerty"
							width={100}
							height={20}
						/>

						<Image
							src="/chubb-gia2.svg"
							alt="Chubb"
							width={140}
							height={100}
						/>

						<Image
							src="/travelers-gia.svg"
							alt="Travelers"
							width={140}
							height={100}
						/>

						<Image
							src="/hartford-gia2.svg"
							alt="The Hartford"
							width={100}
							height={80}
						/>
					</Grid>
				</Section>
			)}
			<Section
				theme={isSmall || isMedium ? "light" : "white"}
				padding={isSmall || isMedium ? "small" : "medium"}
				ruler={false}
			>
				<Grid
					smallColumnNumber={1}
					smallRowNumber={2}
					smallColumnGap="none"
					smallRowGap="large"
					smallWidth={100}
					mediumColumnNumber={1}
					mediumRowNumber={2}
					mediumColumnGap="none"
					mediumRowGap="large"
					mediumWidth={100}
					largeColumnNumber={1}
					largeRowNumber={2}
					largeColumnGap="none"
					largeRowGap="medium"
					largeWidth={100}
					justify="start"
					align="center"
				>
					<Grid
						smallColumnNumber={1}
						smallRowNumber={2}
						smallColumnGap="none"
						smallRowGap="small"
						smallWidth={100}
						mediumColumnNumber={1}
						mediumRowNumber={2}
						mediumColumnGap="none"
						mediumRowGap="small"
						mediumWidth={100}
						largeColumnNumber={1}
						largeRowNumber={2}
						largeColumnGap="none"
						largeRowGap="small"
						largeWidth={75}
						justify="start"
						align="center"
					>
						<Caption>Better Coverage at Better Prices</Caption>
						<Title>
							Get the right coverage for less by comparing prices
							from the best insurance carriers
						</Title>
					</Grid>
					<Grid
						smallColumnNumber={1}
						smallRowNumber={4}
						smallColumnGap="none"
						smallRowGap="medium"
						smallWidth={100}
						mediumColumnNumber={2}
						mediumRowNumber={2}
						mediumColumnGap="none"
						mediumRowGap="medium"
						mediumWidth={100}
						largeColumnNumber={4}
						largeRowNumber={1}
						largeColumnGap="none"
						largeRowGap="none"
						largeWidth={100}
						justify="start"
						align="start"
					>
						<Detail
							title="Simple and fast applications"
							body="Fill out an application in 5 minutes for
								(almost) any type of insurance, personal or
								commercial."
						>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g clipPath="url(#clip0_9_10)">
									<path
										d="M8 10H16M8 18H16M8 14H12M4 21.4V2.6C4 2.44087 4.06321 2.28826 4.17574 2.17574C4.28826 2.06321 4.44087 2 4.6 2H16.252C16.4111 2.00014 16.5636 2.06345 16.676 2.176L19.824 5.324C19.88 5.3799 19.9243 5.44632 19.9545 5.51943C19.9847 5.59254 20.0002 5.6709 20 5.75V21.4C20 21.4788 19.9845 21.5568 19.9543 21.6296C19.9242 21.7024 19.88 21.7685 19.8243 21.8243C19.7685 21.88 19.7024 21.9242 19.6296 21.9543C19.5568 21.9845 19.4788 22 19.4 22H4.6C4.52121 22 4.44319 21.9845 4.37039 21.9543C4.29759 21.9242 4.23145 21.88 4.17574 21.8243C4.12002 21.7685 4.07583 21.7024 4.04567 21.6296C4.01552 21.5568 4 21.4788 4 21.4V21.4Z"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M16 2V5.4C16 5.55913 16.0632 5.71174 16.1757 5.82426C16.2883 5.93679 16.4409 6 16.6 6H20"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</g>
							</svg>
						</Detail>
						<Detail
							title="Your insurance one-stop-shop"
							body="Review coverages from 30+ of the best insurance carriers side-by-side and in one place."
						>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g clipPath="url(#clip0_8_9)">
									<path
										d="M8.99999 6H20M4.99999 6.01L5.00999 5.999M4.99999 12.01L5.00999 11.999M3.79999 17.8L4.59999 18.6L6.59999 16.6M8.99999 12H20M8.99999 18H20"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</g>
							</svg>
						</Detail>
						<Detail
							title="Insurance in a snap"
							body="Find a match, get a better rate, and save. Our licensed agents will help with whatever you need."
						>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g clipPath="url(#clip0_8_8)">
									<path
										d="M8 12L11 15L16 10"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 13.821 2.487 15.53 3.338 17L2.5 21.5L7 20.662C8.51954 21.5411 10.2445 22.0027 12 22V22Z"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</g>
							</svg>
						</Detail>
						<Detail
							title="The only service you will need"
							body="Manage, switch, and update your policies in one place in a matter of seconds with just a few taps or clicks."
						>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g clipPath="url(#clip0_7_2)">
									<path
										d="M12 16.01L12.01 15.999"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M7 19.4V4.6C7 4.44087 7.06321 4.28826 7.17574 4.17574C7.28826 4.06321 7.44087 4 7.6 4H16.4C16.4788 4 16.5568 4.01552 16.6296 4.04567C16.7024 4.07583 16.7685 4.12002 16.8243 4.17574C16.88 4.23145 16.9242 4.29759 16.9543 4.37039C16.9845 4.44319 17 4.52121 17 4.6V19.4C17 19.4788 16.9845 19.5568 16.9543 19.6296C16.9242 19.7024 16.88 19.7685 16.8243 19.8243C16.7685 19.88 16.7024 19.9242 16.6296 19.9543C16.5568 19.9845 16.4788 20 16.4 20H7.6C7.44087 20 7.28826 19.9368 7.17574 19.8243C7.06321 19.7117 7 19.5591 7 19.4V19.4Z"
										stroke="currentColor"
										strokeWidth="1.5"
									/>
								</g>
							</svg>
						</Detail>
					</Grid>
				</Grid>
			</Section>
			<Section
				theme={isSmall || isMedium ? "white" : "light"}
				padding={isSmall || isMedium ? "small" : "medium"}
				ruler={false}
			>
				<Grid
					smallColumnNumber={1}
					smallRowNumber={2}
					smallColumnGap="none"
					smallRowGap="large"
					smallWidth={100}
					mediumColumnNumber={1}
					mediumRowNumber={2}
					mediumColumnGap="none"
					mediumRowGap="large"
					mediumWidth={100}
					largeColumnNumber={2}
					largeRowNumber={1}
					largeColumnGap="none"
					largeRowGap="none"
					largeWidth={100}
					justify="start"
					align="start"
				>
					<Grid
						smallColumnNumber={1}
						smallRowNumber={3}
						smallColumnGap="none"
						smallRowGap="small"
						smallWidth={100}
						mediumColumnNumber={1}
						mediumRowNumber={3}
						mediumColumnGap="none"
						mediumRowGap="small"
						mediumWidth={100}
						largeColumnNumber={1}
						largeRowNumber={3}
						largeColumnGap="none"
						largeRowGap="small"
						largeWidth={75}
						justify="start"
						align="start"
					>
						<Caption>International Insurance</Caption>
						<Title>Available nationwide and worldwide</Title>
						<Body>
							Even if you are looking for watercraft insurance in
							Europe, or commercial cross-border truckin
							insurance, we partner with the best domestic and
							international carriers to make sure you are covered
							wherever you need it.
						</Body>
					</Grid>
					<Picture
						src={IndexImage}
						alt="Family in a car enjoying time together"
					/>
				</Grid>
			</Section>
			<Section
				theme={isSmall || isMedium ? "light" : "white"}
				ruler={false}
				padding={isSmall || isMedium ? "small" : "medium"}
			>
				<Grid
					smallColumnNumber={1}
					smallRowNumber={2}
					smallColumnGap="none"
					smallRowGap="large"
					smallWidth={100}
					mediumColumnNumber={1}
					mediumRowNumber={2}
					mediumColumnGap="none"
					mediumRowGap="large"
					mediumWidth={100}
					largeColumnNumber={2}
					largeRowNumber={1}
					largeColumnGap="none"
					largeRowGap="none"
					largeWidth={100}
					justify="start"
					align="start"
				>
					<Grid
						smallColumnNumber={1}
						smallRowNumber={3}
						smallColumnGap="none"
						smallRowGap="small"
						smallWidth={100}
						mediumColumnNumber={1}
						mediumRowNumber={3}
						mediumColumnGap="none"
						mediumRowGap="small"
						mediumWidth={100}
						largeColumnNumber={1}
						largeRowNumber={3}
						largeColumnGap="none"
						largeRowGap="small"
						largeWidth={75}
						justify="start"
						align="start"
					>
						<Caption>We&apos;re On Your Side</Caption>
						<Title>An agency you can trust</Title>
						<Body>
							Global Insurance has been providing insurance for
							over 30 years. As business professionals with our
							own priorities and loved ones, we understand the
							value of your time and trust. That&apos;s why we
							work hard every day to earn your trust and your
							business. If you&apos;re ready to discuss your
							insurance options, we&apos;re ready to help.
						</Body>
					</Grid>
					<Grid
						smallColumnNumber={1}
						smallRowNumber={2}
						smallColumnGap="none"
						smallRowGap="medium"
						smallWidth={100}
						mediumColumnNumber={1}
						mediumRowNumber={2}
						mediumColumnGap="none"
						mediumRowGap="medium"
						mediumWidth={100}
						largeColumnNumber={2}
						largeRowNumber={1}
						largeColumnGap="none"
						largeRowGap="none"
						largeWidth={100}
						justify="start"
						align="start"
					>
						<Detail
							title="Online experience"
							body="Get insured, quickly and easily (and 100% spam free)"
						>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g clipPath="url(#clip0_10_22)">
									<path
										d="M10.586 10.586L16.95 7.05L13.414 13.414M10.586 10.586L7.04999 16.95L13.414 13.414M10.586 10.586L13.414 13.414"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M19 12H18M6 12H5M12 5V6M12 18V19M7.05 7.05L7.757 7.757M16.243 16.243L16.95 16.95M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22Z"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</g>
							</svg>
						</Detail>
						<Detail
							title="24/7 support"
							body="Life's complicated enough, insurance shouldn't be"
						>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g clipPath="url(#clip0_10_26)">
									<path
										d="M20 11C20 8.87827 19.1571 6.84344 17.6569 5.34315C16.1566 3.84285 14.1217 3 12 3C9.87827 3 7.84344 3.84285 6.34315 5.34315C4.84285 6.84344 4 8.87827 4 11"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M20 18V18.5C20 19.0304 19.7893 19.5391 19.4142 19.9142C19.0391 20.2893 18.5304 20.5 18 20.5H14.5M2 15.438V13.562C2.00007 13.116 2.1492 12.6828 2.4237 12.3313C2.69821 11.9798 3.08232 11.7302 3.515 11.622L5.255 11.186C5.34342 11.164 5.43569 11.1624 5.52481 11.1813C5.61393 11.2003 5.69757 11.2393 5.76937 11.2954C5.84118 11.3515 5.89926 11.4232 5.93922 11.5051C5.97918 11.587 5.99997 11.6769 6 11.768V17.231C6.00016 17.3223 5.97947 17.4125 5.93952 17.4946C5.89957 17.5767 5.84141 17.6486 5.76946 17.7048C5.69752 17.761 5.61369 17.8001 5.52436 17.819C5.43503 17.8379 5.34257 17.8362 5.254 17.814L3.514 17.379C3.08151 17.2707 2.69762 17.0209 2.42331 16.6694C2.149 16.3179 2.00001 15.8849 2 15.439V15.438ZM22 15.438V13.562C21.9999 13.116 21.8508 12.6828 21.5763 12.3313C21.3018 11.9798 20.9177 11.7302 20.485 11.622L18.745 11.186C18.6566 11.164 18.5643 11.1624 18.4752 11.1813C18.3861 11.2003 18.3024 11.2393 18.2306 11.2954C18.1588 11.3515 18.1007 11.4232 18.0608 11.5051C18.0208 11.587 18 11.6769 18 11.768V17.231C17.9999 17.3222 18.0206 17.4122 18.0604 17.4942C18.1003 17.5763 18.1584 17.6481 18.2302 17.7043C18.3021 17.7605 18.3857 17.7996 18.4749 17.8186C18.5641 17.8376 18.6565 17.836 18.745 17.814L20.485 17.379C20.9177 17.2708 21.3018 17.0212 21.5763 16.6697C21.8508 16.3182 21.9999 15.885 22 15.439V15.438Z"
										stroke="currentColor"
										strokeWidth="1.5"
									/>
									<path
										d="M13.5 22H10.5C10.1022 22 9.72064 21.842 9.43934 21.5607C9.15804 21.2794 9 20.8978 9 20.5C9 20.1022 9.15804 19.7206 9.43934 19.4393C9.72064 19.158 10.1022 19 10.5 19H13.5C13.8978 19 14.2794 19.158 14.5607 19.4393C14.842 19.7206 15 20.1022 15 20.5C15 20.8978 14.842 21.2794 14.5607 21.5607C14.2794 21.842 13.8978 22 13.5 22Z"
										stroke="currentColor"
										strokeWidth="1.5"
									/>
								</g>
							</svg>
						</Detail>
					</Grid>
				</Grid>
			</Section>
			<Section
				theme={isSmall || isMedium ? "white" : "light"}
				ruler={false}
				padding={isSmall || isMedium ? "small" : "medium"}
			>
				<Grid
					smallColumnNumber={1}
					smallRowNumber={2}
					smallColumnGap="none"
					smallRowGap="large"
					smallWidth={100}
					mediumColumnNumber={1}
					mediumRowNumber={2}
					mediumColumnGap="none"
					mediumRowGap="large"
					mediumWidth={100}
					largeColumnNumber={1}
					largeRowNumber={2}
					largeColumnGap="none"
					largeRowGap="small"
					largeWidth={100}
					justify="start"
					align="center"
				>
					<Grid
						smallColumnNumber={1}
						smallRowNumber={2}
						smallColumnGap="none"
						smallRowGap="small"
						smallWidth={100}
						mediumColumnNumber={1}
						mediumRowNumber={2}
						mediumColumnGap="none"
						mediumRowGap="small"
						mediumWidth={100}
						largeColumnNumber={1}
						largeRowNumber={2}
						largeColumnGap="none"
						largeRowGap="small"
						largeWidth={75}
						justify="start"
						align="center"
					>
						<Caption>Our Mission</Caption>
						<Title>
							Service will always be our principle objective
						</Title>
					</Grid>
					<Grid
						smallColumnNumber={1}
						smallRowNumber={4}
						smallColumnGap="none"
						smallRowGap="medium"
						smallWidth={100}
						mediumColumnNumber={1}
						mediumRowNumber={4}
						mediumColumnGap="none"
						mediumRowGap="medium"
						mediumWidth={100}
						largeColumnNumber={4}
						largeRowNumber={1}
						largeColumnGap="none"
						largeRowGap="none"
						largeWidth={100}
						justify="start"
						align="center"
					>
						<Detail
							title="Protect"
							body="We protect the interest of all our clients in regards to premiums, claims and quality of products"
						>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g clipPath="url(#clip0_10_26)">
									<path
										d="M20 11C20 8.87827 19.1571 6.84344 17.6569 5.34315C16.1566 3.84285 14.1217 3 12 3C9.87827 3 7.84344 3.84285 6.34315 5.34315C4.84285 6.84344 4 8.87827 4 11"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M20 18V18.5C20 19.0304 19.7893 19.5391 19.4142 19.9142C19.0391 20.2893 18.5304 20.5 18 20.5H14.5M2 15.438V13.562C2.00007 13.116 2.1492 12.6828 2.4237 12.3313C2.69821 11.9798 3.08232 11.7302 3.515 11.622L5.255 11.186C5.34342 11.164 5.43569 11.1624 5.52481 11.1813C5.61393 11.2003 5.69757 11.2393 5.76937 11.2954C5.84118 11.3515 5.89926 11.4232 5.93922 11.5051C5.97918 11.587 5.99997 11.6769 6 11.768V17.231C6.00016 17.3223 5.97947 17.4125 5.93952 17.4946C5.89957 17.5767 5.84141 17.6486 5.76946 17.7048C5.69752 17.761 5.61369 17.8001 5.52436 17.819C5.43503 17.8379 5.34257 17.8362 5.254 17.814L3.514 17.379C3.08151 17.2707 2.69762 17.0209 2.42331 16.6694C2.149 16.3179 2.00001 15.8849 2 15.439V15.438ZM22 15.438V13.562C21.9999 13.116 21.8508 12.6828 21.5763 12.3313C21.3018 11.9798 20.9177 11.7302 20.485 11.622L18.745 11.186C18.6566 11.164 18.5643 11.1624 18.4752 11.1813C18.3861 11.2003 18.3024 11.2393 18.2306 11.2954C18.1588 11.3515 18.1007 11.4232 18.0608 11.5051C18.0208 11.587 18 11.6769 18 11.768V17.231C17.9999 17.3222 18.0206 17.4122 18.0604 17.4942C18.1003 17.5763 18.1584 17.6481 18.2302 17.7043C18.3021 17.7605 18.3857 17.7996 18.4749 17.8186C18.5641 17.8376 18.6565 17.836 18.745 17.814L20.485 17.379C20.9177 17.2708 21.3018 17.0212 21.5763 16.6697C21.8508 16.3182 21.9999 15.885 22 15.439V15.438Z"
										stroke="currentColor"
										strokeWidth="1.5"
									/>
									<path
										d="M13.5 22H10.5C10.1022 22 9.72064 21.842 9.43934 21.5607C9.15804 21.2794 9 20.8978 9 20.5C9 20.1022 9.15804 19.7206 9.43934 19.4393C9.72064 19.158 10.1022 19 10.5 19H13.5C13.8978 19 14.2794 19.158 14.5607 19.4393C14.842 19.7206 15 20.1022 15 20.5C15 20.8978 14.842 21.2794 14.5607 21.5607C14.2794 21.842 13.8978 22 13.5 22Z"
										stroke="currentColor"
										strokeWidth="1.5"
									/>
								</g>
							</svg>
						</Detail>
						<Detail
							title="Innovate"
							body="We diligently pursue innovative products and services that our customers require"
						>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g clipPath="url(#clip0_10_26)">
									<path
										d="M20 11C20 8.87827 19.1571 6.84344 17.6569 5.34315C16.1566 3.84285 14.1217 3 12 3C9.87827 3 7.84344 3.84285 6.34315 5.34315C4.84285 6.84344 4 8.87827 4 11"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M20 18V18.5C20 19.0304 19.7893 19.5391 19.4142 19.9142C19.0391 20.2893 18.5304 20.5 18 20.5H14.5M2 15.438V13.562C2.00007 13.116 2.1492 12.6828 2.4237 12.3313C2.69821 11.9798 3.08232 11.7302 3.515 11.622L5.255 11.186C5.34342 11.164 5.43569 11.1624 5.52481 11.1813C5.61393 11.2003 5.69757 11.2393 5.76937 11.2954C5.84118 11.3515 5.89926 11.4232 5.93922 11.5051C5.97918 11.587 5.99997 11.6769 6 11.768V17.231C6.00016 17.3223 5.97947 17.4125 5.93952 17.4946C5.89957 17.5767 5.84141 17.6486 5.76946 17.7048C5.69752 17.761 5.61369 17.8001 5.52436 17.819C5.43503 17.8379 5.34257 17.8362 5.254 17.814L3.514 17.379C3.08151 17.2707 2.69762 17.0209 2.42331 16.6694C2.149 16.3179 2.00001 15.8849 2 15.439V15.438ZM22 15.438V13.562C21.9999 13.116 21.8508 12.6828 21.5763 12.3313C21.3018 11.9798 20.9177 11.7302 20.485 11.622L18.745 11.186C18.6566 11.164 18.5643 11.1624 18.4752 11.1813C18.3861 11.2003 18.3024 11.2393 18.2306 11.2954C18.1588 11.3515 18.1007 11.4232 18.0608 11.5051C18.0208 11.587 18 11.6769 18 11.768V17.231C17.9999 17.3222 18.0206 17.4122 18.0604 17.4942C18.1003 17.5763 18.1584 17.6481 18.2302 17.7043C18.3021 17.7605 18.3857 17.7996 18.4749 17.8186C18.5641 17.8376 18.6565 17.836 18.745 17.814L20.485 17.379C20.9177 17.2708 21.3018 17.0212 21.5763 16.6697C21.8508 16.3182 21.9999 15.885 22 15.439V15.438Z"
										stroke="currentColor"
										strokeWidth="1.5"
									/>
									<path
										d="M13.5 22H10.5C10.1022 22 9.72064 21.842 9.43934 21.5607C9.15804 21.2794 9 20.8978 9 20.5C9 20.1022 9.15804 19.7206 9.43934 19.4393C9.72064 19.158 10.1022 19 10.5 19H13.5C13.8978 19 14.2794 19.158 14.5607 19.4393C14.842 19.7206 15 20.1022 15 20.5C15 20.8978 14.842 21.2794 14.5607 21.5607C14.2794 21.842 13.8978 22 13.5 22Z"
										stroke="currentColor"
										strokeWidth="1.5"
									/>
								</g>
							</svg>
						</Detail>
						<Detail
							title="Provide"
							body="We provide essential products and services that enhance lives and businesses"
						>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g clipPath="url(#clip0_10_26)">
									<path
										d="M20 11C20 8.87827 19.1571 6.84344 17.6569 5.34315C16.1566 3.84285 14.1217 3 12 3C9.87827 3 7.84344 3.84285 6.34315 5.34315C4.84285 6.84344 4 8.87827 4 11"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M20 18V18.5C20 19.0304 19.7893 19.5391 19.4142 19.9142C19.0391 20.2893 18.5304 20.5 18 20.5H14.5M2 15.438V13.562C2.00007 13.116 2.1492 12.6828 2.4237 12.3313C2.69821 11.9798 3.08232 11.7302 3.515 11.622L5.255 11.186C5.34342 11.164 5.43569 11.1624 5.52481 11.1813C5.61393 11.2003 5.69757 11.2393 5.76937 11.2954C5.84118 11.3515 5.89926 11.4232 5.93922 11.5051C5.97918 11.587 5.99997 11.6769 6 11.768V17.231C6.00016 17.3223 5.97947 17.4125 5.93952 17.4946C5.89957 17.5767 5.84141 17.6486 5.76946 17.7048C5.69752 17.761 5.61369 17.8001 5.52436 17.819C5.43503 17.8379 5.34257 17.8362 5.254 17.814L3.514 17.379C3.08151 17.2707 2.69762 17.0209 2.42331 16.6694C2.149 16.3179 2.00001 15.8849 2 15.439V15.438ZM22 15.438V13.562C21.9999 13.116 21.8508 12.6828 21.5763 12.3313C21.3018 11.9798 20.9177 11.7302 20.485 11.622L18.745 11.186C18.6566 11.164 18.5643 11.1624 18.4752 11.1813C18.3861 11.2003 18.3024 11.2393 18.2306 11.2954C18.1588 11.3515 18.1007 11.4232 18.0608 11.5051C18.0208 11.587 18 11.6769 18 11.768V17.231C17.9999 17.3222 18.0206 17.4122 18.0604 17.4942C18.1003 17.5763 18.1584 17.6481 18.2302 17.7043C18.3021 17.7605 18.3857 17.7996 18.4749 17.8186C18.5641 17.8376 18.6565 17.836 18.745 17.814L20.485 17.379C20.9177 17.2708 21.3018 17.0212 21.5763 16.6697C21.8508 16.3182 21.9999 15.885 22 15.439V15.438Z"
										stroke="currentColor"
										strokeWidth="1.5"
									/>
									<path
										d="M13.5 22H10.5C10.1022 22 9.72064 21.842 9.43934 21.5607C9.15804 21.2794 9 20.8978 9 20.5C9 20.1022 9.15804 19.7206 9.43934 19.4393C9.72064 19.158 10.1022 19 10.5 19H13.5C13.8978 19 14.2794 19.158 14.5607 19.4393C14.842 19.7206 15 20.1022 15 20.5C15 20.8978 14.842 21.2794 14.5607 21.5607C14.2794 21.842 13.8978 22 13.5 22Z"
										stroke="currentColor"
										strokeWidth="1.5"
									/>
								</g>
							</svg>
						</Detail>
						<Detail
							title="Trust"
							body="We create a secure and trustworthy environment"
						>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g clipPath="url(#clip0_10_26)">
									<path
										d="M20 11C20 8.87827 19.1571 6.84344 17.6569 5.34315C16.1566 3.84285 14.1217 3 12 3C9.87827 3 7.84344 3.84285 6.34315 5.34315C4.84285 6.84344 4 8.87827 4 11"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M20 18V18.5C20 19.0304 19.7893 19.5391 19.4142 19.9142C19.0391 20.2893 18.5304 20.5 18 20.5H14.5M2 15.438V13.562C2.00007 13.116 2.1492 12.6828 2.4237 12.3313C2.69821 11.9798 3.08232 11.7302 3.515 11.622L5.255 11.186C5.34342 11.164 5.43569 11.1624 5.52481 11.1813C5.61393 11.2003 5.69757 11.2393 5.76937 11.2954C5.84118 11.3515 5.89926 11.4232 5.93922 11.5051C5.97918 11.587 5.99997 11.6769 6 11.768V17.231C6.00016 17.3223 5.97947 17.4125 5.93952 17.4946C5.89957 17.5767 5.84141 17.6486 5.76946 17.7048C5.69752 17.761 5.61369 17.8001 5.52436 17.819C5.43503 17.8379 5.34257 17.8362 5.254 17.814L3.514 17.379C3.08151 17.2707 2.69762 17.0209 2.42331 16.6694C2.149 16.3179 2.00001 15.8849 2 15.439V15.438ZM22 15.438V13.562C21.9999 13.116 21.8508 12.6828 21.5763 12.3313C21.3018 11.9798 20.9177 11.7302 20.485 11.622L18.745 11.186C18.6566 11.164 18.5643 11.1624 18.4752 11.1813C18.3861 11.2003 18.3024 11.2393 18.2306 11.2954C18.1588 11.3515 18.1007 11.4232 18.0608 11.5051C18.0208 11.587 18 11.6769 18 11.768V17.231C17.9999 17.3222 18.0206 17.4122 18.0604 17.4942C18.1003 17.5763 18.1584 17.6481 18.2302 17.7043C18.3021 17.7605 18.3857 17.7996 18.4749 17.8186C18.5641 17.8376 18.6565 17.836 18.745 17.814L20.485 17.379C20.9177 17.2708 21.3018 17.0212 21.5763 16.6697C21.8508 16.3182 21.9999 15.885 22 15.439V15.438Z"
										stroke="currentColor"
										strokeWidth="1.5"
									/>
									<path
										d="M13.5 22H10.5C10.1022 22 9.72064 21.842 9.43934 21.5607C9.15804 21.2794 9 20.8978 9 20.5C9 20.1022 9.15804 19.7206 9.43934 19.4393C9.72064 19.158 10.1022 19 10.5 19H13.5C13.8978 19 14.2794 19.158 14.5607 19.4393C14.842 19.7206 15 20.1022 15 20.5C15 20.8978 14.842 21.2794 14.5607 21.5607C14.2794 21.842 13.8978 22 13.5 22Z"
										stroke="currentColor"
										strokeWidth="1.5"
									/>
								</g>
							</svg>
						</Detail>
					</Grid>
				</Grid>
			</Section>
		</Page>
	);
}
