import { motion } from "framer-motion";
import { useState } from "react";
import { ReactNode } from "react";

import styles from "./Link.module.css";

interface Props {
	children?: ReactNode;
}

const SubItemVariants = {
	hidden: {
		x: -4,
		color: "#2e63eb",
		transition: {
			type: "tween",
			ease: "linear",
			duration: 0.2,
		},
	},
	visible: {
		x: 0,
		color: "#0a2540",
		transition: {
			type: "tween",
			ease: "linear",
			duration: 0.2,
		},
	},
};

const SubItemVariants2 = {
	hidden: {
		x: 0,
		color: "#2e63eb",
		transition: {
			type: "tween",
			ease: "linear",
			duration: 0.2,
		},
	},
	visible: {
		x: 3,
		color: "#0a2540",
		transition: {
			type: "tween",
			ease: "linear",
			duration: 0.2,
		},
	},
};

const SubItemVariants3 = {
	hidden: {
		color: "#2e63eb",
		transition: {
			type: "tween",
			ease: "linear",
			duration: 0.2,
		},
	},
	visible: {
		color: "#0a2540",
		transition: {
			type: "tween",
			ease: "linear",
			duration: 0.2,
		},
	},
};

export default function Link({ children }: Props) {
	const [isHovered, setIsHovered] = useState(false);
	return (
		<motion.a
			onHoverStart={() => setIsHovered(true)}
			onHoverEnd={() => {
				setIsHovered(false);
			}}
			className={styles.link}
		>
			<motion.span
				variants={SubItemVariants3}
				initial="hidden"
				animate={isHovered ? "visible" : "hidden"}
				className={styles.linkText}
			>
				{children}
			</motion.span>
			<svg
				width="10"
				height="10"
				viewBox="0 0 10 10"
				className={styles.linkIcon}
			>
				<g fillRule="evenodd">
					{isHovered && (
						<motion.path
							d="M0 5h7"
							fill="none"
							strokeWidth={2}
							stroke="currentColor"
							variants={SubItemVariants}
							initial="hidden"
							animate={isHovered ? "visible" : false}
						></motion.path>
					)}
					<motion.path
						d="M1 1l4 4-4 4"
						fill="none"
						strokeWidth={2}
						stroke="currentColor"
						variants={SubItemVariants2}
						initial="hidden"
						animate={isHovered ? "visible" : "hidden"}
					></motion.path>
				</g>
			</svg>
		</motion.a>
	);
}
