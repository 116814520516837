import { motion } from "framer-motion";
import { useState } from "react";
import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";

import styles from "./HeroTitle.module.css";

interface Props {
	first: string;
	second: string;
	third: string;
}

const variants1 = {
	initial: {
		opacity: 0,
	},
	animate: {
		opacity: [1, 1, 0, 0, 0, 0, 1],
		transition: {
			type: "tween",
			ease: "linear",
			duration: 6,
			repeat: Infinity,
		},
	},
};

const variants2 = {
	initial: {
		opacity: 0,
	},
	animate: {
		opacity: [0, 0, 1, 1, 0, 0, 0],
		transition: {
			type: "tween",
			ease: "linear",
			duration: 6,
			repeat: Infinity,
		},
	},
};

const variants3 = {
	initial: {
		opacity: 0,
	},
	animate: {
		opacity: [0, 0, 0, 0, 1, 1, 0],
		transition: {
			type: "tween",
			ease: "linear",
			duration: 6,
			repeat: Infinity,
		},
	},
};

export default function HeroTitle({ first, second, third }: Props) {
	//
	const [mounted, setMounted] = useState(false);
	//
	const isSmall = useMediaQuery({ maxWidth: 599 });
	const isMedium = useMediaQuery({ minWidth: 600, maxWidth: 1079 });
	const isLarge = useMediaQuery({ minWidth: 1080 });
	//
	// useEffect only runs on the client, so now we can safely show the UI
	useEffect(() => {
		setMounted(true);
	}, []);
	if (!mounted) {
		return null;
	}
	return (
		<div id="HeroTitle" className={styles.heroTitle}>
			{isSmall && (
				<div id="smallHeroTitle" className={styles.smallHeroTitle}>
					<div className={styles.smallHeroTitleContainer}>
						<div className={styles.smallHeroTitleText}>
							Compare.
						</div>
						<motion.div
							className={styles.smallHeroTitleFirstOverlay}
							variants={variants1}
							initial="initial"
							animate="animate"
						>
							Compare.
						</motion.div>
					</div>
					<div className={styles.smallHeroTitleContainer}>
						<div className={styles.smallHeroTitleText}>Choose.</div>
						<motion.div
							className={styles.smallHeroTitleSecondOverlay}
							variants={variants2}
							initial="initial"
							animate="animate"
						>
							Choose.
						</motion.div>
					</div>
					<div className={styles.smallHeroTitleContainer}>
						<div className={styles.smallHeroTitleText}>Save.</div>
						<motion.div
							className={styles.smallHeroTitleThirdOverlay}
							variants={variants3}
							initial="initial"
							animate="animate"
						>
							Save.
						</motion.div>
					</div>
				</div>
			)}
			{isMedium && (
				<div id="mediumHeroTitle" className={styles.mediumHeroTitle}>
					<div className={styles.mediumHeroTitleContainer}>
						<div className={styles.mediumHeroTitleText}>
							Compare.
						</div>
						<motion.div
							className={styles.mediumHeroTitleFirstOverlay}
							variants={variants1}
							initial="initial"
							animate="animate"
						>
							Compare.
						</motion.div>
					</div>
					<div className={styles.mediumHeroTitleContainer}>
						<div className={styles.mediumHeroTitleText}>
							Choose.
						</div>
						<motion.div
							className={styles.mediumHeroTitleSecondOverlay}
							variants={variants2}
							initial="initial"
							animate="animate"
						>
							Choose.
						</motion.div>
					</div>
					<div className={styles.mediumHeroTitleContainer}>
						<div className={styles.mediumHeroTitleText}>Save.</div>
						<motion.div
							className={styles.mediumHeroTitleThirdOverlay}
							variants={variants3}
							initial="initial"
							animate="animate"
						>
							Save.
						</motion.div>
					</div>
				</div>
			)}
			{isLarge && (
				<div id="largeHeroTitle" className={styles.largeHeroTitle}>
					<div className={styles.largeHeroTitleContainer}>
						<div className={styles.largeHeroTitleText}>
							Compare.
						</div>
						<motion.div
							className={styles.largeHeroTitleFirstOverlay}
							variants={variants1}
							initial="initial"
							animate="animate"
						>
							{first}
						</motion.div>
					</div>
					<div className={styles.largeHeroTitleContainer}>
						<div className={styles.largeHeroTitleText}>Choose.</div>
						<motion.div
							className={styles.largeHeroTitleSecondOverlay}
							variants={variants2}
							initial="initial"
							animate="animate"
						>
							{second}
						</motion.div>
					</div>
					<div className={styles.largeHeroTitleContainer}>
						<div className={styles.largeHeroTitleText}>Save.</div>
						<motion.div
							className={styles.largeHeroTitleThirdOverlay}
							variants={variants3}
							initial="initial"
							animate="animate"
						>
							{third}
						</motion.div>
					</div>
				</div>
			)}
		</div>
	);
}
