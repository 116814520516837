import { ReactNode } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";

import Link from "../Link/Link";
import styles from "./Detail.module.css";

interface Props {
	title?: string;
	body?: string;
	footer?: string;
	children?: ReactNode;
}

export default function Detail({ title, body, footer, children }: Props) {
	//
	const [mounted, setMounted] = useState(false);
	//
	const isSmall = useMediaQuery({ maxWidth: 599 });
	const isMedium = useMediaQuery({ minWidth: 600, maxWidth: 1079 });
	const isLarge = useMediaQuery({ minWidth: 1080 });
	//
	// useEffect only runs on the client, so now we can safely show the UI
	useEffect(() => {
		setMounted(true);
	}, []);
	if (!mounted) {
		return null;
	}
	return (
		<div id="Detail" className={styles.detail}>
			{isSmall && (
				<div id="smallDetail" className={styles.smallDetail}>
					<div className={styles.smallDetailIconContainer}>
						<div className={styles.smallDetailIcon}>{children}</div>
					</div>
					<div className={styles.smallDetailTitle}>{title}</div>
					<div className={styles.smallDetailBody}>{body}</div>
				</div>
			)}
			{isMedium && (
				<div id="mediumDetail" className={styles.mediumDetail}>
					<div className={styles.mediumDetailIconContainer}>
						<div className={styles.mediumDetailIcon}>
							{children}
						</div>
					</div>
					<div className={styles.mediumDetailTitle}>{title}</div>
					<div className={styles.mediumDetailBody}>{body}</div>
				</div>
			)}
			{isLarge && (
				<div id="largeDetail" className={styles.largeDetail}>
					<div className={styles.largeDetailIconContainer}>
						<div className={styles.largeDetailIcon}>{children}</div>
					</div>
					<div className={styles.largeDetailTitle}>{title}</div>
					<div className={styles.largeDetailBody}>{body}</div>
					{footer && (
						<div className={styles.largeDetailFooterContainer}>
							<Link>Testing this</Link>
						</div>
					)}
				</div>
			)}
		</div>
	);
}
