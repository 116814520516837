import Link from "next/link";

import { motion } from "framer-motion";
import { ReactNode } from "react";
import { useState } from "react";

import styles from "./Card.module.css";

interface Props {
	text: string;
	href: string;
	children?: ReactNode;
}

const SubItemVariants = {
	hidden: {
		x: -4,
		transition: {
			type: "tween",
			ease: "linear",
			duration: 0.2,
		},
	},
	visible: {
		x: 0,
		transition: {
			type: "tween",
			ease: "linear",
			duration: 0.2,
		},
	},
};

const SubItemVariants2 = {
	hidden: {
		x: 0,
		transition: {
			type: "tween",
			ease: "linear",
			duration: 0.2,
		},
	},
	visible: {
		x: 3,
		transition: {
			type: "tween",
			ease: "linear",
			duration: 0.2,
		},
	},
};

const SubItemVariants3 = {
	hidden: {
		backgroundColor: "#2e63eb",
		transition: {
			type: "tween",
			ease: "linear",
			duration: 0.2,
		},
	},
	visible: {
		backgroundColor: "#0a2540",
		transition: {
			type: "tween",
			ease: "linear",
			duration: 0.2,
		},
	},
};

export default function Card({ text, href, children }: Props) {
	const [isHovered, setIsHovered] = useState(false);
	return (
        (<Link href={href} passHref className={styles.cardWrapper}>

            <motion.button
                onHoverStart={() => setIsHovered(true)}
                onHoverEnd={() => {
                    setIsHovered(false);
                }}
                className={styles.card}
            >
                <motion.div
                    className={styles.imageWrapper}
                    variants={SubItemVariants3}
                    initial="hidden"
                    animate={isHovered ? "visible" : "hidden"}
                >
                    {children}
                </motion.div>
                <div className={styles.textWrapper}>
                    <div className={styles.text}>{text}</div>
                    <svg
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        className={styles.arrow}
                    >
                        <g fillRule="evenodd">
                            {isHovered && (
                                <motion.path
                                    d="M0 5h7"
                                    fill="none"
                                    strokeWidth={2}
                                    stroke="currentColor"
                                    variants={SubItemVariants}
                                    initial="hidden"
                                    animate={isHovered ? "visible" : false}
                                ></motion.path>
                            )}
                            <motion.path
                                d="M1 1l4 4-4 4"
                                fill="none"
                                strokeWidth={2}
                                stroke="currentColor"
                                variants={SubItemVariants2}
                                initial="hidden"
                                animate={isHovered ? "visible" : "hidden"}
                            ></motion.path>
                        </g>
                    </svg>
                </div>
            </motion.button>

        </Link>)
    );
}
