import { useState } from "react";
import { ReactNode } from "react";
import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";

import styles from "./Caption.module.css";

interface Props {
	children?: ReactNode;
}

export default function Caption({ children }: Props) {
	//
	const [mounted, setMounted] = useState(false);
	//
	const isSmall = useMediaQuery({ maxWidth: 599 });
	const isMedium = useMediaQuery({ minWidth: 600, maxWidth: 1079 });
	const isLarge = useMediaQuery({ minWidth: 1080 });
	//
	// useEffect only runs on the client, so now we can safely show the UI
	useEffect(() => {
		setMounted(true);
	}, []);
	if (!mounted) {
		return null;
	}
	return (
		<div id="Caption" className={styles.caption}>
			{isSmall && <h2 className={styles.smallCaption}>{children}</h2>}
			{isMedium && <h2 className={styles.mediumCaption}>{children}</h2>}
			{isLarge && <h2 className={styles.largeCaption}>{children}</h2>}
		</div>
	);
}
