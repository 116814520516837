import dynamic from "next/dynamic";

import { ReactNode, Suspense } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";

import styles from "./HeroSection.module.css";

const Waves = dynamic(() => import("../Waves/Waves"), {
	ssr: true,
});

interface Props {
	theme: string;
	ruler: boolean;
	children?: ReactNode;
}

export default function HeroSection({ theme, children }: Props) {
	//
	const [mounted, setMounted] = useState(false);
	//
	const isSmall = useMediaQuery({ maxWidth: 599 });
	const isMedium = useMediaQuery({ minWidth: 600, maxWidth: 1079 });
	const isLarge = useMediaQuery({ minWidth: 1080 });
	//
	// useEffect only runs on the client, so now we can safely show the UI
	useEffect(() => {
		setMounted(true);
	}, []);
	if (!mounted) {
		return null;
	}
	return (
		<div id="HeroSection" className={styles.heroSection} data-theme={theme}>
			{isSmall && (
				<div id="smallHeroSection" className={styles.smallHeroSection}>
					<div className={styles.smallHeroSectionContent}>
						{children}
					</div>
					<Suspense fallback={<div />}>
						<Waves />
					</Suspense>
				</div>
			)}
			{isMedium && (
				<div
					id="mediumHeroSection"
					className={styles.mediumHeroSection}
				>
					<div className={styles.mediumHeroSectionContent}>
						{children}
					</div>
					<Suspense fallback={<div />}>
						<Waves />
					</Suspense>
				</div>
			)}
			{isLarge && (
				<div id="largeHeroSection" className={styles.largeHeroSection}>
					<div className={styles.largeHeroSectionContent}>
						{children}
					</div>
					<Suspense fallback={<div />}>
						<Waves />
					</Suspense>
				</div>
			)}
		</div>
	);
}
